<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">{{$t('ERROR_PAGE_404_OOPS_LOST')}}</h4>
          <p class="text-muted">{{$t('ERROR_PAGE_404_OOPS_LOST_DESCRIPTION')}}</p>
        </div>
        <CInput
          class="mb-3"
          :placeholder="$t('ERROR_PAGE_404_OOPS_LOST_WHAT_LOOKING_FOR')"
        >
          <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
          <template #append>
            <CButton color="info">Search</CButton>
          </template>
        </CInput>
      </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
